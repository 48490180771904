import React from 'react'

/**
 * Encapsulates the test page of the frontend.
 */
export default class Test extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        return (
            <p>{`It is now ${new Date().toString()}`}</p>
        )
    }
}